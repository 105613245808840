import React, {useState, useEffect, useMemo} from 'react'
import {withGlobal} from '../shared/app'
import GmapInput from './gmap-input'
import Select from 'react-select'
import Countries from 'i18n-iso-countries'
import {asArray} from '../../lib/utils'

const Block = ({className = '', t, countries, lang, form = {}, onChange = () => {}}) => {
  const defaultForm = {
    zipcode: '',
    city: '',
    country: '',
    country_code: '',
    latlng: '',
    place_id: '',
    region: '',
    state: '',
    street: '',
    street_number: '',
    address: '',
    additionalInfo: '',
    info: '',
    meta: {}
  }

  const [defaultCountries, setDefaultCountries] = useState(
    countries || Object.keys(Countries.getAlpha2Codes())
  )

  const isGeocoded = () => !!form.latlng

  const onAddressChange = (address) => {
    let element = $('.errors-scope').find(`[erralias="shipping.address"]`)
    let errorZone = $('.errors-scope').find(`[errzone="shipping.address"]`)

    element.removeClass('is-invalid')
    errorZone.addClass('d-none')

    if (address && address.geocode && Object.keys(address.geocode).length) {
      if (
        address.geocode.country_code &&
        !defaultCountries.includes(address.geocode.country_code.toUpperCase())
      ) {
        element.addClass('is-invalid')

        errorZone.removeClass('d-none').html(t(`common.errors.countries.unavailable`))

        onChange({
          ...defaultForm,
          ...(address.address && {address: address.address}),
          manual: false
        })
      } else {
        onChange({
          ...defaultForm,
          ...address.geocode,
          ...(address.address && {address: address.address}),
          ...(address.latlng && {latlng: address.latlng}),
          manual: false
        })
      }
    } else {
      onChange({address: address.address})
    }
  }

  const countriesList = useMemo(() => {
    if (!defaultCountries) return []
    const items = defaultCountries.map((iso) => ({
      value: iso,
      label: Countries.getName(iso, lang, {select: 'official'})
    }))

    return items.sort((a, b) => {
      if (!a.label || !b.label) return 0
      const result = new Intl.Collator(lang).compare(a.label.toLowerCase(), b.label.toLowerCase())
      return result
    })
  }, [lang, defaultCountries])

  const onFormChange = (e) => {
    onChange({...form, [e.target.name]: e.target.value})
  }

  useEffect(() => {
    asArray(process.env.LANGS).forEach((l) => {
      Countries.registerLocale(require(`i18n-iso-countries/langs/${l}.json`))
    })

    setDefaultCountries([...defaultCountries])

    onChange({...defaultForm, ...form})
  }, [])

  const handleCountryChange = (data, meta) => {
    if (!['select-option'].includes(meta.action)) return

    const country = {country: data.label, country_code: data.value}
    onChange({...form, ...country})

    let element = $('.errors-scope').find(`[errkey='shipping.country']`)
    let errorZone = $('.errors-scope').find(`[errkey='shipping.country']`)

    element.removeClass('is-invalid')
    errorZone.addClass('d-none')
  }

  return (
    <div className={`${className}`}>
      <div className='form-group'>
        <hr />
        <GmapInput
          value={form.address || ''}
          shortAddress
          onChange={onAddressChange}
          placeholder={t('common.address')}
          className='form-control'
          erralias='shipping.address'
          manualOption={{
            active: !form.manual,
            onSelect: () => {
              onChange({...form, manual: true})
            }
          }}
        />
        <div
          errzone='shipping.address'
          className='err-alert alert alert-danger-light p-1 my-2 d-none'>
          address err zone
        </div>
      </div>

      {form.manual && (
        <form autoComplete='on'>
          <div className='form-group row'>
            <div className='col-6' style={{paddingRight: '8px'}}>
              <input
                errkey='shipping.zipcode'
                name='zipcode'
                value={form.zipcode}
                onChange={onFormChange}
                type='text'
                className='form-control'
                placeholder={t('common.zipcode')}
              />
            </div>
            <div className='col-6' style={{paddingLeft: '8px'}}>
              <input
                errkey='shipping.city'
                name='city'
                value={form.city}
                onChange={onFormChange}
                type='text'
                className='form-control'
                placeholder={t('common.city')}
              />
            </div>
          </div>
          <div className='form-group row'>
            <div className='col-6' style={{paddingRight: '8px'}}>
              <input
                errkey='shipping.state'
                name='state'
                value={form.state}
                onChange={onFormChange}
                type='text'
                className='form-control'
                placeholder={t('common.state')}
              />
            </div>
            <div className='col-6' style={{paddingLeft: '8px'}}>
              <Select
                defaultValue={countriesList.find((c) => c.value === form.country_code)}
                errkey='shipping.country'
                onChange={handleCountryChange}
                onInputChange={handleCountryChange}
                options={countriesList}
                placeholder={t('common.country')}
              />
              <div
                errzone='shipping.country'
                className='err-alert alert alert-danger-light p-1 my-2 d-none'>
                country err zone
              </div>
            </div>
          </div>
          <div className='form-group'>
            <textarea
              rows='2'
              errkey='shipping.info'
              name='info'
              value={form.info}
              onChange={onFormChange}
              className='form-control'
              placeholder={t('common.shipping-info')}
            />
          </div>
        </form>
      )}

      {!form.manual && isGeocoded() && (
        <form autoComplete='off'>
          <div className='form-group row'>
            <div className='col-6' style={{paddingRight: '8px'}}>
              <input
                disabled
                name='zipcode'
                value={form.zipcode}
                type='text'
                onChange={onFormChange}
                className='form-control'
                placeholder={t('common.zipcode')}
              />
            </div>
            <div className='col-6' style={{paddingLeft: '8px'}}>
              <input
                disabled
                name='city'
                value={form.city}
                type='text'
                onChange={onFormChange}
                className='form-control'
                placeholder={t('common.city')}
              />
            </div>
          </div>
          <div className='form-group row'>
            <div className='col-6' style={{paddingRight: '8px'}}>
              <input
                disabled
                name='state'
                value={form.state}
                type='text'
                onChange={onFormChange}
                className='form-control'
                placeholder={t('common.state')}
              />
            </div>
            <div className='col-6' style={{paddingLeft: '8px'}}>
              <input
                disabled
                name='country'
                value={form.country}
                type='text'
                onChange={onFormChange}
                className='form-control'
                placeholder={t('common.country')}
              />
            </div>
          </div>
          <div className='form-group'>
            <textarea
              rows='2'
              name='info'
              value={form.info}
              onChange={onFormChange}
              className='form-control'
              placeholder={t('common.shipping-info')}
            />
          </div>
        </form>
      )}
    </div>
  )
}

export default withGlobal(Block)
