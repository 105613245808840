import {withGlobal} from '../shared/app'

const TOS = ({t}) => {
  const tosContent = t(`onboarding.tos.text`)
  const tosBtn = t(`onboarding.tos.btn`)
  const link = t(`onboarding.tos.link`)

  const fullContent = tosContent.split(tosBtn)

  fullContent.splice(
    1,
    0,
    <a rel='noreferrer' target='_blank' className='link' href={link}>
      {tosBtn}
    </a>
  )

  return (
    <div className='toc w-100 py-2 text-justify'>
      <div className=''>
        {fullContent.map((b, index) => (
          <span key={`home-footer-tos-${index}`}>{b}</span>
        ))}
      </div>
      <style jsx>
        {`
          .toc {
            font-size: 10px;
            line-height: 11px;
            color: #9e9e9e;
          }

          .link {
            cursor: pointer;
            color: #32735f;
            text-decoration: underline !important;
            text-align: justify;
          }

          @media (max-width: 500px) {
            // sm down
            .toc {
              font-size: 8px;
            }
          }
        `}
      </style>
    </div>
  )
}

export default withGlobal(TOS)
