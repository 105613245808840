import {useState} from 'react'
import {withGlobal} from '../shared/app'

const GDPR = ({t, hidden = false}) => {
  const [more, setMore] = useState(false)

  if (hidden) return null

  return (
    <div className='gdpr pb-2' onClick={() => setMore(!more)}>
      {more ? t(`onboarding.rgpd.full`) : t(`onboarding.rgpd.small`)}
      {!more && <span className='link'>{t(`onboarding.rgpd.more`)}</span>}
      <style jsx>
        {`
          .gdpr {
            font-size: 10px;
            line-height: 11px;
            color: #9e9e9e;
          }

          .link {
            cursor: pointer;
            color: #32735f;
            text-decoration: underline;
            text-align: justify;
          }

          @media (max-width: 500px) {
            // sm down
            .gdpr {
              font-size: 8px;
            }
          }
        `}
      </style>
    </div>
  )
}

export default withGlobal(GDPR)
